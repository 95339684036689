<template>
    <nav>
        <ul class="nav-links">
            <li class="home">
                <a href="#">Home</a>
            </li>
            <li class="plants">
                <a href="./components/plantsPlants.vue">Plants</a>
            </li>
            <li class="insects">
                <a href="#">Insects</a>
            </li>
            <li class="fishes">
                <a href="#">Fishes</a>
            </li>
            <li class="birds">
                <a href="#">Birds</a>
            </li>
            <li class="reptiles">
                <a href="#">Reptiles</a>
            </li>
            <li class="mammals">
                <a href="#">Mammals</a>
            </li>
            <li class="human">
                <a href="#">Human</a>
            </li>
                   </ul>
                   
    </nav>
  </template>

<script>
export default {
  name: 'navBar',
  props: {
    msg: String
  }
}
</script>

<style scoped>


ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items:flex-start;
    
    }
li {
    margin-top: 2rem;
    padding: 1rem;
        border-radius: 7px ;
    cursor: pointer;
    box-shadow: 1px 1px 5px 1px rgb(0, 0, 0, 0.2);
    
    }


a {
    text-decoration: none;
    font-size: 2rem;
   }

.home {
    background-color: #1f35FF;
    
}

.home a {
    color: #eaeaea;
}
.home:hover{
    background-color: #000b7f;
  transition: all 0.5s ease-in-out;
}

.plants{
background-color: #00791B;
}

.plants a {
    color: #eaeaea;
}

.plants:hover {
    background-color: #00970f;
    transition: all 0.5 ease-in-out;
}

.insects {
    background-color: #404040;
}
.insects a {
    color: #eaeaea;
}

.insects:hover {
    background-color: #828282;
    transition: all 0.5s ease-in-out;
}

.fishes{
background-color: #0008c7;
}
.fishes:hover{
background-color: #565bff;
transition: all 0.5s ease-in-out;
}

.fishes a{
color: #eaeaea;
}

.birds {
    background-color: #680079;
}

.birds:hover {
    background-color: #b900d5;
    transition: all 0.5s ease-in-out;
}

.birds a{
    color: #eaeaea;
}

.reptiles{
   background-color: #007C97;
}

.reptiles:hover {
   background-color: #00acd3;
   transition: all 0.5s ease-in-out;
}

.reptiles a{
    color: #eaeaea;
}

.mammals {
    background-color: #775f00;
}

.mammals:hover {
    background-color: #d3a900;
    transition: all 0.5s ease-in-out;
}

.mammals a{
    color: #eaeaea;
}
.human {
    background-color: #ffffff;
}
.human:hover {
    background-color: #d2d2d2;
    transition: all 0.5s ease-in-out;
}

.human a{
    color: #000000;
}
</style>