<template>
  <div class="header">
    <h1 class="titleSite">Creation</h1>
  <h3>"In the beginning, God created..."</h3>
    </div>
  <div class="marginBottom"></div>
</template>

<script>
export default {
  name: 'titleCreation',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.titleSite {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: "Alkatra", system-ui;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
color: rgb(0, 0, 111);
font-size: 4rem;
margin: 20px auto 20px auto;
}
h3 {
  font-family: "Kolker Brush", cursive;
  font-weight: 200;
  font-style: normal;
display: flex;
justify-content: center;
font-size: 3rem;
margin-top: 2px;
margin-bottom: 30px;

}
.marginBottom {
  border-bottom: solid 3px black;
}

</style>
