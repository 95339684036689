<template>
  <header>
  <img alt="Earth Sunrise" src="./img/creatia.png">

  <titleCreation msg="Creatia"/>
</header>
<nav-bar />
</template>

<script>
import titleCreation from './components/titleCreation.vue'
import navBar from './components/navBar.vue'

export default {
  components: {
    titleCreation,
    navBar
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0
}
header img {
  width: auto;
  height: 40vh;
}



</style>
